import React from 'react'
import Layout from '../components/layout'
import Container from '../components/container';

const NotFoundPage = () => (
  <Layout>
    <Container>
      <section className="not-found-page">
        <h1>404</h1>
        <p>Page not found.</p>
      </section>
    </Container>
  </Layout>
)

export default NotFoundPage
